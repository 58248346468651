import theme from "@/theme/theme";
import { ToastOptions, toast } from "react-toastify";

export const success = (content: string | React.ReactNode, options?: ToastOptions) => {
  const { style, ...restOptions } = options || {};
  toast.success(content, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeButton: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    style: {
      backgroundColor: "#05CF74",
      color: "#1A2135",
      fontSize: "14px",
      padding: "0px 15px",
      ...style,
    },
    ...restOptions,
  });
};

export const warning = (content: string | React.ReactNode, options?: ToastOptions) => {
  const { style, ...restOptions } = options || {};
  toast.warning(content, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeButton: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    style: {
      backgroundColor: "#ffe49a",
      color: "#1A2135",
      fontSize: "14px",
      padding: "0px 15px",
      ...style,
    },
    ...restOptions,
  });
};

export const error = (content: string | React.ReactNode, options?: ToastOptions) => {
  const { style, ...restOptions } = options || {};
  toast.error(content, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeButton: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    style: {
      backgroundColor: theme.palette.commonColors.danger,
      color: theme.palette.commonColors.white,
      fontSize: "14px",
      padding: "0px 15px",
      ...style,
    },
    ...restOptions,
  });
};

export default {
  success,
  error,
  warning,
};

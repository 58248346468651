import Button from "@/common/components/button";
import { Typography } from "@mui/material";

interface ViewRowBottomDisplayProps {
  id?: string | number;
  rowSelectedId?: string | number;
  handleCancel?: () => void;
  handleConfirm?: () => void;
}
export default function ViewRowBottomDisplay({
  id,
  rowSelectedId,
  handleCancel,
  handleConfirm,
}: ViewRowBottomDisplayProps) {
  if (!id && !rowSelectedId) return;
  if (id === rowSelectedId) {
    return (
      <td colSpan={5} style={{ padding: "0px 16px 12px 16px" }}>
        <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography fontSize="14px" fontWeight="bold">
            Are you sure you want to reset this user’s password?
          </Typography>
          <div style={{ display: "flex", alignItems: "center", gap: "24px" }}>
            <Button data-testid="cancelUserPasswordReset" variant="secondary" size="sm" onClick={handleCancel}>
              Cancel
            </Button>
            <Button data-testid="confirmUserPasswordReset" variant="primary" size="sm" onClick={handleConfirm}>
              Confirm
            </Button>
          </div>
        </div>
      </td>
    );
  }
}

import TsTable from "@/common/components/ts-table";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getCoreRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import { DataImportColumns } from "./columns";
import { CheckCircleIcon, DocumentArrowDownIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { DataImportFiltersProps } from "../filters";
import {
  hideSeqEnrollDialog,
  openViewDrawer,
  setCurrentLeadSource,
  triggerSeqEnrollLeadSourceId,
} from "@/redux/reducers/dataImport/slices/list";
import SeqEnrollDialog from "@/common/components/enroll-in-sequence/SeqEnrollDialog";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import toast from "@/lib/toast";

export default function DataImportTable({ filters, setFilters }: DataImportFiltersProps) {
  const dispatch = useAppDispatch();
  const data = useAppSelector(({ dataImport }) => dataImport.list.data) || [];

  const seqEnrollShowDialog = useAppSelector(({ dataImport }) => dataImport.list.isShowSeqEnrollDialog);
  const currentLeadSource = useAppSelector(({ dataImport }) => dataImport.list.currentLeadSource);
  const seqEnrollLeadSourceIdList = useAppSelector(({ dataImport }) => dataImport.list.seqEnrollLeadSourceIdList);
  const seqEnrollPending = useAppSelector(({ prospects }) => prospects.listProspects.seqEnrollPending);

  const table = useReactTable({
    data,
    columns: DataImportColumns({ filters, setFilters }) as any,
    pageCount: data.length,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    getPaginationRowModel: getPaginationRowModel(),
  });

  const handleSeqEnrollClose = () => {
    dispatch(hideSeqEnrollDialog());
  };

  /**
   * Callback for Dialog submit.
   * @param sequenceId
   */
  const handleSeqEnroll = (sequenceId: string) => {
    const leadSourceId = seqEnrollLeadSourceIdList.length
      ? seqEnrollLeadSourceIdList[0]
      : (currentLeadSource as any).id
      ? (currentLeadSource as any).id
      : undefined;

    if (!leadSourceId || !sequenceId) {
      return;
    }

    dispatch(
      triggerSeqEnrollLeadSourceId({
        sequenceTemplateId: sequenceId,
        leadSourceId,
      }),
    )
      .unwrap()
      .then((response) => {
        if (response.successfulCount) {
          if (response.skippedDNCCount) {
            toast.success(
              <>
                <span>
                  Successfully enrolled {response.successfulCount}{" "}
                  {response.successfulCount > 1 ? "prospects" : "prospect"}, with {response.skippedDNCCount}{" "}
                  {response.skippedDNCCount > 1 ? "prospects" : "prospect"} skipped due to <b>DNC (Do Not Call)</b>{" "}
                  status.
                </span>
              </>,
              {
                icon: <CheckCircleIcon />,
              },
            );
          } else {
            toast.success(
              <>
                <span>
                  Successfully enrolled {response.successfulCount}{" "}
                  {response.successfulCount > 1 ? "prospects" : "prospect"} to a sequence.
                </span>
              </>,
              {
                icon: <CheckCircleIcon />,
              },
            );
          }
        } else if (response.skippedDNCCount) {
          toast.warning(
            <>
              <span>
                Prospects with <b>DNC (Do Not Call)</b> status can't be enrolled into a sequence.
              </span>
            </>,
            {
              icon: <ExclamationTriangleIcon />,
            },
          );
        } else {
          toast.error("Something went wrong!");
        }

        dispatch(hideSeqEnrollDialog());
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };

  return (
    <>
      <FullLoadingOverlay loading={seqEnrollPending} overlay={true} />
      <TsTable
        table={table as any}
        notFoundProps={{
          Icon: DocumentArrowDownIcon,
          title: "No imports found",
          description: (
            <span>
              Begin importing prospects, companies, or products <br /> by clicking the <b>"New Import"</b> button above.
            </span>
          ),
        }}
        handleCellClick={(_, data) => {
          dispatch(openViewDrawer({}));
          dispatch(setCurrentLeadSource(data));
        }}
      />

      <SeqEnrollDialog isOpen={seqEnrollShowDialog} handleClose={handleSeqEnrollClose} handleSubmit={handleSeqEnroll} />
    </>
  );
}

import MenuOptions from "@/common/components/menu-options/MenuOptions";
import toast from "@/lib/toast";
import { impersonateUser } from "@/redux/reducers/admin/users/slices/impersonation";
import { setSelectedUser } from "@/redux/reducers/admin/users/slices/reset-passoword";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { EyeIcon, ShieldCheckIcon } from "@heroicons/react/24/outline";
import { CircularProgress } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router";

const iconStyles = {
  width: "24px",
  height: "24px",
  stroke: "#1A2135",
};

enum ActionMenuOptionEnum {
  Impersonate = "impersonate",
  ResetPassword = "reset_password",
}

type TableActionButtonProps = {
  id: string;
  userId: string;
  userName: string;
  userEmail: string;
  canResetPassword: boolean;
};

export default function TableActionButton({
  id,
  userId,
  userName,
  userEmail,
  canResetPassword,
}: TableActionButtonProps) {
  const [open, setOpen] = React.useState(false);
  const anchorElRef = React.useRef<null | HTMLElement>(null);
  const { loading } = useAppSelector((selector) => selector.teams.impersonateUser);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const options = [
    {
      label: "Impersonate",
      value: ActionMenuOptionEnum.Impersonate,
      icon: <EyeIcon style={iconStyles} />,
    },
    {
      label: "Reset Password",
      value: ActionMenuOptionEnum.ResetPassword,
      icon: <ShieldCheckIcon style={iconStyles} />,
      disabled: !canResetPassword,
    },
  ];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    anchorElRef.current = event.currentTarget;
    setOpen(true);
  };

  const handleClose = () => {
    anchorElRef.current = null;
    setOpen(false);
  };

  const handleImpersonate = async () => {
    dispatch(impersonateUser(userId))
      .then(() => {
        navigate("/");
        navigate(0);
      })
      .catch((err) => toast.error("An error occurred when trying impersonation"));
  };

  const handleResetPassword = async () => {
    dispatch(
      setSelectedUser({
        selectedRowId: id,
        selectedUserId: userId,
        selectedUserName: userName,
        selectedUserEmail: userEmail,
      }),
    );
    handleClose();
  };

  const onSelectOption = (option: (typeof options)[0]) => {
    switch (option.value) {
      case ActionMenuOptionEnum.Impersonate:
        handleImpersonate();
        break;
      case ActionMenuOptionEnum.ResetPassword:
        handleResetPassword();
        break;
      default:
        handleClose();
        break;
    }
  };
  if (loading) return <CircularProgress color="inherit" size={20} />;

  return (
    <MenuOptions
      open={open}
      anchorEl={anchorElRef.current}
      handleClick={handleClick}
      handleClose={handleClose}
      options={options}
      onClick={onSelectOption}
    />
  );
}

import toast from "@/lib/toast";
import { stopImpersonation } from "@/redux/reducers/admin/users/slices/impersonation";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { CircularProgress, Snackbar } from "@mui/material";
import SnackbarContent from "@mui/material/SnackbarContent";
import { EyeOffOutline } from "heroicons-react";
import { useNavigate } from "react-router-dom";
import Button from "../button";
import { useRoles } from "../roles/useRoles";

interface ActionProps {
  onClick: () => void;
}

const action = ({ onClick }: ActionProps) => {
  return (
    <Button
      size="sm"
      sx={{
        background: "black",
        color: "white",
        "&:hover": {
          backgroundColor: "white !important",
          color: "black !important",
        },
      }}
      rightIcon={<EyeOffOutline />}
      onClick={onClick}
    >
      Stop Impersonating
    </Button>
  );
};

export default function LongTextSnackbar() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loading } = useAppSelector((selector) => selector.teams.impersonateUser);

  const {
    auth0: { user },
  } = useRoles();

  const stopImpersonationAction = () => {
    dispatch(stopImpersonation())
      .then(() => {
        navigate("/");
        navigate(0);
      })
      .catch((err) => {
        toast.error("An error occurred when trying to stop impersonating");
      });
  };

  return (
    <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={!!user?.is_impersonation}>
      <SnackbarContent
        sx={{ background: "#ffe49a", color: "#1a2135" }}
        message={`You are impersonating user ${user?.impersonated_user?.name} (${user?.impersonated_user?.email})`}
        action={
          loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            action({ onClick: () => stopImpersonationAction() })
          )
        }
      />
    </Snackbar>
  );
}

import Accordion from "@/common/components/accordion";
import { ProspectInfoBasic } from "@/common/components/prospect-info/ProspectInfoBasic";
import { TitleAndSubtitle } from "@/common/components/title-and-subtitle";
import AudioPlayer from "@/pages/inbox/AudioPlayer";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { KnCallMessage } from "@/services/generated";
import { capitalize } from "@/utils/capitalize";
import { CloudArrowDownIcon } from "@heroicons/react/24/outline";
import { Box, Divider, Link, Stack, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { CALL_FLOW_OUTCOME_OPTIONS, CALL_FLOW_TYPE_OPTIONS } from "../../constants";
import { useEffect, useRef, useState } from "react";
import { getCallSummary } from "@/redux/reducers/ai-voice/getCallSummary";
import { useParams } from "react-router-dom";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";

export type CallSummary = {
  pageSize?: number;
  pageNumber?: number;
};

export default function CallSummary() {
  const dispatch = useAppDispatch();

  const { call, callSummary, loadingDetails } = useAppSelector(({ aiVoice }) => aiVoice.listCalls);
  const [currPageSize, setCurrPageSize] = useState<number>(25);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isLoadingInitialData, setIsLoadingInitialData] = useState<boolean>(true);
  const observerRef = useRef<HTMLDivElement | null>(null);

  const {
    call_began_time,
    call_direction = "",
    call_flow,
    call_outcome,
    call_recording_url = "",
    call_duration,
    contact,
  } = call || {};

  const theme = useTheme();
  const params = useParams();
  const id = params.id;

  // Duration from dayjs expects milliseconds
  const durationInMs = call_duration ? call_duration * 1000 : undefined;

  const callDuration = durationInMs ? dayjs.duration(durationInMs).format("m[m] s[s]") : "n/a";
  const callDate = call_began_time ? dayjs(call_began_time).format("MMM DD, YYYY h:mm A") : "n/a";

  const message = (message: KnCallMessage) => {
    return (
      <Box>
        <Typography color={theme.palette.txt.placeholder} fontSize={12}>
          [{dayjs(message.created_at).format("h:mm A")}]
        </Typography>
        <Box sx={{ display: "block" }}>
          <Typography display="inline" fontSize={12} fontWeight={500}>
            {capitalize(message.user || "Test")}:&nbsp;
          </Typography>
          <Typography display="inline" fontSize={12}>
            {message.text}
          </Typography>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    if (callSummary?.results && !loadingDetails) {
      setIsLoadingInitialData(false);
    }

    const handleObserver = (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting && !isFetching && callSummary?.hasNext && id) {
        setIsFetching(true);
        dispatch(getCallSummary({ id, pageSize: currPageSize + 25 })).finally(() => {
          setCurrPageSize((prevSize) => prevSize + 25);
          setIsFetching(false);
        });
      }
    };

    const observer = new IntersectionObserver(handleObserver, { threshold: 1.0 });
    if (observerRef.current) observer.observe(observerRef.current);

    return () => observer.disconnect();
  }, [callSummary, isFetching, currPageSize]);

  return (
    <Box sx={{ maxWidth: 985, bgcolor: "background.paper" }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "auto 450px",
          gap: 2,
        }}
      >
        <Box>
          <AudioPlayer url={call_recording_url} />

          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: 2 }}>
            <Box>
              <Typography fontWeight={500} fontSize={12}>
                CALL DATE AND TIME
              </Typography>
              <Typography fontSize={14}>{callDate}</Typography>
            </Box>
            <Box>
              <Typography fontWeight={500} fontSize={12}>
                CALL DURATION
              </Typography>
              <Typography fontSize={14}>{callDuration}</Typography>
            </Box>
            <Link
              href={call_recording_url}
              sx={{
                textDecoration: "none !important",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  lineHeight: 12,
                  gap: 1,
                }}
              >
                <Typography sx={{ color: theme.palette.commonColors.informative }} fontSize={16}>
                  Download Audio
                </Typography>
                <CloudArrowDownIcon height={24} color={theme.palette.commonColors.informative} />
              </Box>
            </Link>
          </Box>

          <Divider />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              py: 2,
              height: "calc(100dvh - 230px)",
              overflowY: "auto",
            }}
          >
            {loadingDetails && isLoadingInitialData ? (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <LoadingSpinner
                  height="40"
                  boxStyles={false}
                  spinnerWidth={40}
                  spinnerHeight={40}
                  spinnerMarginBottom={0}
                />
              </Box>
            ) : (
              callSummary && callSummary.results?.map((m) => message(m))
            )}

            <div ref={observerRef}>
              {loadingDetails && !isLoadingInitialData && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    py: 2,
                  }}
                >
                  <LoadingSpinner
                    height="40"
                    boxStyles={false}
                    spinnerWidth={40}
                    spinnerHeight={40}
                    spinnerMarginBottom={0}
                  />
                </Box>
              )}
            </div>
          </Box>
        </Box>
        <Stack
          spacing={2}
          sx={({ palette }) => ({
            background: palette.midnight[20],
            width: "100%",
            overflowY: "scroll",
            flexDirection: "column",
            padding: 2,
            borderRadius: 2,
            height: "85vh",
          })}
        >
          {contact && (
            <Accordion summaryTitle="Prospect" defaultExpanded id="Prospect">
              <ProspectInfoBasic prospect={contact || {}} />
            </Accordion>
          )}

          <Accordion summaryTitle="Basic Call Details" defaultExpanded id="Basic-Call-Details">
            <Stack gap={1} sx={{ paddingLeft: "4px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <TitleAndSubtitle title="CALL DATE AND TIME" subtitle={callDate} />
                <TitleAndSubtitle title="CALL DURATION" subtitle={callDuration} />
              </Box>

              <Box sx={{ justifyContent: "space-between", display: "flex" }}>
                <TitleAndSubtitle
                  title="CALL OUTCOME"
                  subtitle={call_outcome ? CALL_FLOW_OUTCOME_OPTIONS[call_outcome]?.label : ""}
                  customWidth="70%"
                />
              </Box>
              <Box style={{ justifyContent: "space-between", display: "flex" }}>
                <TitleAndSubtitle title="CALL TYPE" subtitle={capitalize(call_direction)} />
                <TitleAndSubtitle
                  title="CALL FLOW"
                  subtitle={call_flow ? CALL_FLOW_TYPE_OPTIONS[call_flow]?.label : ""}
                />
              </Box>
              {call_recording_url && (
                <TitleAndSubtitle
                  title="RECORDING LINK"
                  subtitle={
                    <Link
                      sx={{ textDecoration: "none !important" }}
                      onClick={() => window.open(call_recording_url, "_blank")}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          lineHeight: 12,
                          gap: 1,
                        }}
                      >
                        <Typography sx={{ color: theme.palette.commonColors.informative }} fontSize={16}>
                          Call Recording
                        </Typography>
                      </Box>
                    </Link>
                  }
                  customWidth="70%"
                />
              )}
            </Stack>
          </Accordion>
        </Stack>
      </Box>
    </Box>
  );
}

import { combineReducers } from "@reduxjs/toolkit";
import getTeamsReducer from "./teams/slices/getTeams";
import impersonateReducer from "./users/slices/impersonation";

import resetUserPasswordReducer from "./users/slices/reset-passoword";

const teamsReducer = combineReducers({
  getTeams: getTeamsReducer,
  impersonateUser: impersonateReducer,
  resetUserPassword: resetUserPasswordReducer,
});

export default teamsReducer;

import TableActionButton from "./ActionButton";
import { createColumnHelper, CellContext } from "@tanstack/react-table";
import { Prospect } from "@/redux/reducers/prospects/slices/types";
import TableDate from "@/common/components/table-date";
import ConsentStatusChip from "@/common/components/status-chip/ConsentStatusChip";
import createCheckboxCol from "@/common/functions/createCheckboxCol";
import TableHeader from "@/common/components/table-header";
import TableCell from "@/common/components/table-cell";
import { getConsentFromSources } from "@/common/functions/consent";

const columnHelper = createColumnHelper<Prospect>();

export const ProspectColumns = [
  createCheckboxCol(columnHelper),

  columnHelper.accessor("firstName", {
    header: () => <TableHeader>Prospect</TableHeader>,

    cell: (info) => {
      const row = info.row;
      return <TableCell>{`${info.getValue()}  ${row.original.lastName}`}</TableCell>;
    },
  }),

  columnHelper.accessor("ownerUserFullName", {
    header: () => <TableHeader>Assignee</TableHeader>,
    cell: (info) => {
      return <TableCell>{info.getValue()}</TableCell>;
    },
  }),

  columnHelper.accessor("companyName", {
    header: () => <TableHeader>Primary Company</TableHeader>,
    cell: (info) => {
      return <TableCell>{info.getValue()}</TableCell>;
    },
  }),

  columnHelper.accessor("consentSources", {
    header: () => <TableHeader>Consent</TableHeader>,
    cell: (info) => {
      return <ConsentStatusChip consent={getConsentFromSources(info.getValue())} />;
    },
  }),

  columnHelper.accessor("createdAt", {
    header: () => <TableHeader>Created Date</TableHeader>,
    cell: (info: CellContext<Prospect, number>) => {
      return <TableDate nanos={info?.getValue()} />;
    },
  }),

  columnHelper.display({
    id: "actions",
    header: () => <TableHeader>Actions</TableHeader>,
    cell: (props) => <TableActionButton params={props} />,
  }),
];

import { LoginMethodsEnum } from "@/common/constants/login-methods";
import { capitalize } from "@mui/material";

const getLoginMethod = (userId: string | undefined): string => {
  if (!userId) {
    return capitalize(LoginMethodsEnum.UNKNOWN);
  }

  if (/^auth0/.test(userId)) {
    return capitalize(LoginMethodsEnum.EMAIL);
  } else if (/^google-oauth2/.test(userId)) {
    return capitalize(LoginMethodsEnum.GOOGLE);
  }
  return capitalize(LoginMethodsEnum.UNKNOWN);
};

export default getLoginMethod;

import Services from "@/services";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

type State = {
  loading: boolean;
  error: boolean;
};

const initialState: State = {
  loading: false,
  error: false,
};

export const impersonateUser = createAsyncThunk("admin/impersonate", async (userId: string, { rejectWithValue }) => {
  try {
    const response = await Services.Admin.impersonateUser(userId);

    return response;
  } catch (error) {
    rejectWithValue(error.message);
  }
});

export const stopImpersonation = createAsyncThunk("admin/stopImpersonation", async (_, { rejectWithValue }) => {
  try {
    const response = await Services.Admin.stopImpersonation();

    return response;
  } catch (error) {
    rejectWithValue(error.message);
  }
});

const impersonationSlice = createSlice({
  name: "impersonateUser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(impersonateUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(impersonateUser.fulfilled, (state) => {
        state.loading = false;
        state.error = false;
      })
      .addCase(impersonateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(stopImpersonation.pending, (state) => {
        state.loading = true;
      })
      .addCase(stopImpersonation.fulfilled, (state) => {
        state.loading = false;
        state.error = false;
      })
      .addCase(stopImpersonation.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const {} = impersonationSlice.actions;

export default impersonationSlice.reducer;

import ImpersonationBanner from "@/common/components/impersonation-banner";
import UnauthorizedPage from "@/common/components/unauthorized-page";
import MainLayout from "@/layouts/main";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import userflow from "userflow.js";

export default function Layout() {
  const { user, isAuthenticated } = useAuth0();

  useEffect(() => {
    userflow.identify(user?.sub || "unknown", {
      name: user?.name,
      email: user?.email,
    });
  }, [user]);

  return (
    <>
      {isAuthenticated ? (
        <MainLayout>
          <Outlet />
          <ImpersonationBanner />
        </MainLayout>
      ) : (
        <UnauthorizedPage />
      )}
    </>
  );
}

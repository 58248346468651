import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import config from "@/common/constants/config";
import axios from "axios";

type State = {
  selectedRowId: string | undefined;
  selectedUserId: string | undefined;
  selectedUserName: string | undefined;
  selectedUserEmail: string | undefined;

  resetUserPasswordStatusLoading?: boolean;
  resetUserPasswordError?: string | undefined;
};

const initialState: State = {
  selectedRowId: undefined,
  selectedUserId: undefined,
  selectedUserName: undefined,
  selectedUserEmail: undefined,
  resetUserPasswordStatusLoading: false,
  resetUserPasswordError: undefined,
};

export const sendResetUserPassword = createAsyncThunk(
  "admin/resetPassword",
  async (userEmail: string | undefined, { rejectWithValue }) => {
    const url = `https://${config.auth0Domain}/dbconnections/change_password`;

    const body = {
      client_id: config.auth0ClientId,
      email: userEmail,
      connection: "Username-Password-Authentication",
    };

    try {
      const response = await axios.post(url, body);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

const selectedUserSlice = createSlice({
  name: "selectedUser",
  initialState,
  reducers: {
    setSelectedUser: (state, action: PayloadAction<State | undefined>) => {
      state.selectedRowId = action.payload?.selectedRowId;
      state.selectedUserId = action.payload?.selectedUserId;
      state.selectedUserName = action.payload?.selectedUserName;
      state.selectedUserEmail = action.payload?.selectedUserEmail;
    },
    resetSelectedUser: (state) => {
      state.selectedRowId = undefined;
      state.selectedUserId = undefined;
      state.selectedUserName = undefined;
      state.selectedUserEmail = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendResetUserPassword.pending, (state) => {
        state.resetUserPasswordStatusLoading = true;
        state.resetUserPasswordError = undefined;
      })
      .addCase(sendResetUserPassword.fulfilled, (state) => {
        state.resetUserPasswordStatusLoading = false;
      })
      .addCase(sendResetUserPassword.rejected, (state, action) => {
        state.resetUserPasswordStatusLoading = false;
        state.resetUserPasswordError = action.payload as string;
      });
  },
});

export const { setSelectedUser, resetSelectedUser } = selectedUserSlice.actions;

export default selectedUserSlice.reducer;

import StatusChip from "@/common/components/status-chip/StatusChip";
import { TaskRequestStatusEnum } from "@/services/generated";
import { Box, Typography } from "@mui/material";
import { X } from "heroicons-react";

export interface SidePanelHeaderProps {
  title: string;
  closeAction?: () => void;
  status?: TaskRequestStatusEnum;
  isLoading?: boolean;
}

export default function SidePanelHeader(props: SidePanelHeaderProps) {
  const { title, closeAction, status, isLoading = false } = props;

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "12px 12px 12px 16px",
        borderRadius: "6px",
        background: theme.palette.midnight.p,
        width: "100%",
        maxHeight: "52px",
      })}
    >
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
        {isLoading ? (
          <Typography
            sx={(theme) => ({
              color: theme.palette.txt.inverse,
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "28px",
              letterSpacing: "0.2px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            Loading ...{" "}
          </Typography>
        ) : (
          <>
            <Typography
              sx={(theme) => ({
                color: theme.palette.txt.inverse,
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "28px",
                letterSpacing: "0.2px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              })}
            >
              {title}
            </Typography>
            <div style={{ marginRight: "12px", display: status ? "flex" : "none" }}>
              <StatusChip status={status as TaskRequestStatusEnum} type="task" />
            </div>
          </>
        )}
      </Box>
      <Box
        sx={{ background: "transparent", color: "white", alignSelf: "center", display: "flex", cursor: "pointer" }}
        onClick={closeAction}
      >
        <X />
      </Box>
    </Box>
  );
}

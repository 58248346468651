import { Consent } from "@/redux/reducers/prospects/slices/types";
import { Chip, useTheme } from "@mui/material";

type Props = {
  // TODO: Update for DNC
  consent: boolean;
};

const ConsentStatusChip = ({ consent }: Props) => {
  const theme = useTheme();

  const defaultStyles = {
    borderRadius: "16px",
    padding: "4px 8px",
    color: "#fff",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: "0.2px",
    "& .MuiChip-label": {
      paddingLeft: "4px",
      paddingRight: "4px",
    },
  };

  const getConsentStatusStyles = () => {
    // TODO: Update for DNC
    if (consent) {
      return {
        ...defaultStyles,
        backgroundColor: theme.palette.mint.p,
        color: theme.palette.txt.default,
      };
    } else {
      return {
        ...defaultStyles,
        backgroundColor: theme.palette.icon.disabled,
        color: theme.palette.txt.default,
      };
    }
  };

  const getLabel = () => {
    // TODO: Update for DNC
    if (consent) {
      return "Consent";
    } else {
      return "No Consent";
    }
  };

  const getBadgeColor = () => {
    return getConsentStatusStyles();
  };

  return <Chip label={getLabel()} sx={() => getBadgeColor()} />;
};

export default ConsentStatusChip;
